
export default [
    // {
    //     path: 'clients',
    //     name: 'clients',
    //     icon:'user',
    //     component: () => import('@/pages/Clients/Clients'),
    // },
    {
        path: 'main_page',
        name: 'main_page',
        icon:'home',
        component: () => import('@/views/Dashboards/Default.vue'),
    },
    {
        path: 'add_client',
        name: 'add_client',
        icon:'user-add',
        component: () => import('@/pages/ClientPage/ClientPage.vue'),
    },
    {
        path: 'customer_request',
        name: 'customer_request',
        icon:'plus-square',
        component: () => import('@/pages/Global/GlobalPage'),
    },
    {
        path: 'kanban',
        name: 'kanban',
        icon:'appstore',
        component: () => import('@/pages/Kanban/Kanban'),
    },
    {
        path: 'reports',
        name: 'reports',
        icon:'file-text',
        component: () => import('@/pages/Reports/Reports'),
    },
    {
        path: 'organization_request',
        name: 'organization_request',
        icon:'plus-square',
        component: () => import('@/pages/OrganizationRequest/OrganizationRequest'),
    },
    {
        path: 'create_request',
        name: 'create_request',
        component: () => import('@/pages/CreateRequest/CreateRequest'),
    },
    {
        path: 'create_users',
        name: 'create_users',
        icon:'user-add',
        component: () => import('@/pages/Leaf/pages/CreateUsers/CreateUsers.vue'),
    },
    {
        path: 'profile',
        name: 'profile',
        icon:'user-add',
        component: () => import('@/pages/ProfilePage/ProfilePage'),
    }
    // {
    //     path: 'deals',
    //     name: 'deals',
    //     icon:'unordered-list',
    //     component: () => import('@/pages/Deals/Deals'),
    // },

];
