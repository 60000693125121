import Vue from 'vue';
import Router from 'vue-router';
import Main from '@/layouts/Dashboard';
import homeRoutes from './home';
import i18n from '../configs/i18n'; // Импортируйте i18n

Vue.use(Router);

const supportedLanguages = ['en', 'ru', 'kz'];

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/:lang/login',
			name: 'login',
			component: () => import('../views/Sign-In.vue'),
		},
		{
			path: '/:lang/change_password',
			name: 'change_password',
			component: () => import('@/pages/Leaf/pages/ChangePassword/ChangePassword.vue'),
		},
		{
			path: '/:lang/password_reset',
			name: 'password_reset',
			component: () => import('@/pages/Leaf/pages/PasswordReset/PasswordReset.vue'),
		},
		// {
		// 	// will match everything
		// 	path: '/:lang/*',
		// 	component: () => import('../views/404.vue'),
		// },
		{
			path: '/',
			name:'home',
			redirect: `/${i18n.locale}/home/main_page`, // Редирект на язык по умолчанию
		},
		{
			path: '/:lang/home',
			component: Main,
			children: homeRoutes,
			meta: { requiresAuth: true },
		},
		// {
		// 	path: '*',
		// 	redirect: `/${i18n.locale}/home`, // Редирект на язык по умолчанию
		// },
	],
});

router.beforeEach((to, from, next) => {
	const lang = to.params.lang;

	if (!supportedLanguages.includes(lang)) {
		return next(`/${i18n.locale}${to.path}`);
	}

	if (i18n.locale !== lang) {
		i18n.locale = lang;
	}

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	// console.log('requiresAuth', requiresAuth)
	const user = JSON.parse(localStorage.getItem('user'));
	// console.log('requiresAuth && !user', requiresAuth && !user)
	if (requiresAuth && !user) {
		next({ name: 'login', params: { lang: lang } })
	} else {
		next();
	}
});

export default router;
