<template>
  <div>
    <custom-drawer :visible="visible" ref="refCustomDrawer" v-if="data" :key_delete_router="['phone_number', 'stab']" @close="close">
      <template slot="title">
        {{data.data.full_name}}
      </template>
      <template slot="tabs">
        <custom-tabs :tabs="tabs" :visible="visible"/>
      </template>
      <template slot="content">
        <div class="flex w-full" style="margin-top: 100px;">
          <div class="w-full" >
            <div class="w-full" v-if="$route.query.stab === 'main'">
              <ClientInfo :data="data.data"/>
              <tabular v-if="data_test" @click="open_request" ref="refTabularTest" :row-data="data_test" :column-defs="columns"/>
            </div>
          </div>
        </div>
      </template>
      <template slot="content_bottom">
        <a-dropdown >
          <color-icon/>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="edit">
                {{$t('edit')}}
              </a>
            </a-menu-item>
            <a-menu-item @click="clone_delete_client">
              <a>
                {{$t('delete')}}
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import FormAndAgGrid from "@/components/Forms/FormAddClient/FormAndAgGrid";
import Actions from "@/components/Forms/Actions";
import validate_iin from "@/Mixins/validate/validate_iin";
import validate_email from "@/Mixins/validate/validate_email";
import Card from "@/crm_components/card/Card";
import AgGridTable from "@/components/Forms/FormAddClient/AgGridTable";
import CardV2 from "@/crm_components/card_v2/CardV2";
import BigIcon from "@/crm_components/icons/BigIcon";
import FormSelect from "@/components/Forms/FormAddClient/FormSelect";
import formSaveMixin from "@/crm_components/form_save/FormSaveMixin";
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer";
import CardDrawer from "@/crm_components/CustomDrawer/CardDrawer";
import InfoDrawer from "@/crm_components/CustomDrawer/InfoDrawer";
import FormattedDateMixin from "@/crm_components/mixins/FormattedDateMixin";
import ClientInfo from "@/pages/AddClient/Info/ClientInfo";
import ColorIcon from "@/crm_components/icons/ColorIcon";
import CustomTabs from "@/crm_components/custom_tabs/CustomTabs";
import SelectView from "@/pages/CustomerRequest/SelectView";
import AddRequest from "@/pages/AddClient/AddRequest";
import RequestShowDrawer from "@/pages/AddClient/Request/RequestShowDrawer";
import ColumnDefsCreateRequest from "@/pages/AddClient/RequestMixins/ColumnDefsCreateRequest";
import DeleteMixin from "@/pages/AddClient/Client/DeleteMixin/DeleteMixin";
import RoleMixin from "@/Mixins/RoleMixin";
import Tabulator from '@/components/CustomTabular/tabulator-master/dist/js/tabulator.js'
import Tabular from "@/components/CustomTabular/Tabular.vue";
import {EventBus} from "@/configs/eventBus";
export default {
  name: "Client",
  components: {
    Tabular,
    RequestShowDrawer,
    AddRequest,
    CustomTabs,
    ColorIcon,
    InfoDrawer,
    CardDrawer,
    CustomDrawer,
    BigIcon,
    CardV2,
    Card,
    FormAndAgGrid,
    Actions,
    AgGridTable,
    FormSelect,
    ClientInfo
  },
  data(){
    return{
      tabs:[
        {name:this.$t('requests'), key:'main'},
        {name:this.$t('call_history'), key:'call_history'},
      ],
      data_test:null,
      columns: [
        {
          headerName: "#",
          title: "#",
          use:true,
          headerSort: false,
          field: "request_number_v2",
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: this.$t('name'),
          title: this.$t('name'),
          headerSort: false,
          use:true,
          field: "name",
          minWidth: 150,
          cellClass: ['a-link', 'cursor-pointer'],
          open_drawer:true,
        },
        {
          headerName: "Дата",
          title: "Дата",
          headerSort: false,
          use:true,
          field: "created",
          minWidth: 150,
        },
        {
          headerName: this.$t('status'),
          title: this.$t('status'),
          headerSort: false,
          use:true,
          field: "status_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('result'),
          title: this.$t('result'),
          headerSort: false,
          use:true,
          field: "result_request.name",
          minWidth: 150,
        },
      ]
    }
  },
  mixins:[
    ColumnDefsCreateRequest,
    formSaveMixin,
    DeleteMixin,
    RoleMixin,
  ],
  props:{
    visible:{
      type:Boolean,
      default: false
    },
    data:{
      type: Object,
      default: {}
    }
  },
  mounted() {
    // this.create_table()
  },
  watch:{
    data(){
      this.create_table()
    }
  },
  methods:{
    edit(){
      this.$emit('edit')
    },
    async open_drawer_request(data, edit = false){
      this.$emit('open_drawer_request', data, edit)
    },
    clone_delete_client(){
      this.delete_method('clients_event_bus', 'organizations/profiles_client/', this.data.data)

    },
    close(){
      this.$emit('close')
    },
    create_table(){
      if (this.data.data.customer_request.length > 0){
        this.data_test = this.data.data.customer_request
      }else{
        this.data_test = null
      }
    },
    open_request(data){
      EventBus.$emit('event_request_show_drawer', data.data, false);
    }
  }
}
</script>

<style scoped>

</style>
