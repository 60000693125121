<template>
  <div>
    <custom-drawer :visible="visible" ref="refNotification" :key_delete_router="[]" @close="close">
      <template slot="title">
        <div>
          {{ $t('notification_list') }}
        </div>
      </template>
      <template slot="content">
        <div style="margin-top: 50px; overflow: auto;">
          <a-list item-layout="horizontal" :data-source="GET_NOTIFICATION_LIST">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta v-if="item.event_type === 'а_request_has_been_created'">
                <div slot="title">
                  <div class="flex" style="margin-top: 5px;">
                    {{ $t('а_request_has_been_created', {...item.data, author: item.author.full_name}) }}
                    <div class="a-link ml-1 cursor-pointer" @click="open_drawer_request(item)">
                      #{{item.data.request_number_v2}}
                      {{item.data.name}}
                    </div>
                  </div>
                </div>
                <a-avatar shape="square" slot="avatar">
                  <a-icon type="file-add" />
                </a-avatar>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
        </div>
      </template>
    </custom-drawer>
  </div>
</template>

<script>
import CustomDrawer from "@/crm_components/CustomDrawer/CustomDrawer.vue";
import {EventBus} from "@/configs/eventBus";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Notification",
  data(){
    return{
      visible:false,
    }
  },
  async mounted() {
    EventBus.$on('event_notification_open', this.event_notification_open);
  },
  components: {
    CustomDrawer,
  },
  computed:{
    ...mapGetters({
      GET_NOTIFICATION_LIST:'client/GET_NOTIFICATION_LIST',
    })
  },
  methods:{
    ...mapActions({
      NOTIFICATION_LIST:'client/NOTIFICATION_LIST',
    }),
    close(){
      this.visible=false
    },
    event_notification_open(){
      this.visible = true
      this.NOTIFICATION_LIST()
    },
    open_drawer_request(data){
      EventBus.$emit('event_request_show_drawer', data.data, false);
    },
  }
}
</script>

<style lang="scss">
.ant-avatar-string{
  display: flex;
  align-items: center;
  height: 100%;
}
</style>