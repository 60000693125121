<template>

  <!-- Main Sidebar -->
  <a-layout-sider
      collapsible
      class="sider-primary"
      breakpoint="lg"
      collapsed-width="0"
      width="250px"
      :collapsed="sidebarCollapsed"
      @collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
      :trigger="null"
      :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
      theme="light"
      :style="{ backgroundColor: 'transparent',}">
    <div class="brand" v-if="sidebarMinimized">
      <img :src="require('@/assets/images/CRMLogo.png')" alt="">
    </div>
    <div v-else class="flex justify-center">
      <img :src="require('@/assets/images/CRMLogo.png')" alt="" style="width: 100px;">
    </div>
    <hr>

    <!-- Sidebar Navigation Menu -->
    <a-menu theme="light" mode="inline"
            :open-keys="openKeys"
            @openChange="onOpenChange">
      <a-menu-item v-for="(item, index) in routes_clone_v" :key="index">
        <router-link :to="localizedRoute(`/home/${item.path}`)">
						<span class="icon">
							<a-icon :type="item.icon"  class="m-0" />
						</span>
          <span class="label">{{$t(item.name)}}</span>
        </router-link>
      </a-menu-item>
    </a-menu>
    <!-- / Sidebar Navigation Menu -->

  </a-layout-sider>
  <!-- / Main Sidebar -->

</template>

<script>
import routes from "@/router/home";
import {mapGetters} from "vuex";
export default ({
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },
    sidebarMinimized: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      rootSubmenuKeys: ['dashboards', 'pages', 'applications', 'ecommerce', 'authentication', 'basic', 'components', 'changelog'],
      openKeys: this.$route.meta.sidebarMap,
      routes_clone:routes
    }
  },
  computed: {
    ...mapGetters({
      GET_PROFILE_INFO:'user/GET_PROFILE_INFO',
    }),
    currentLang() {
      return this.$route.params.lang;
    },
    routes_clone_v(){
      console.log('routes_clone', this.routes_clone)
      console.log('GET_PROFILE_INFO', this.GET_PROFILE_INFO)
      let _routes = []
      if (this.GET_PROFILE_INFO){
        let pages_front = this.GET_PROFILE_INFO.pages_front
        console.log('pages_front', pages_front)
        this.routes_clone.forEach(async (item)=>{
          if(pages_front.find(ele=>ele.name===item.name)){
            _routes.push(item)
          }
        })
      }
      return _routes
    }
  },
  methods: {
    onOpenChange(openKeys)
    {
      const latestOpenKey = openKeys.find( key => this.openKeys.indexOf( key ) === -1) ;

      if ( this.rootSubmenuKeys.indexOf( latestOpenKey ) === -1 )
      {
        this.openKeys = openKeys;
      }
      else
      {
        this.openKeys = latestOpenKey ? [ latestOpenKey ] : [] ;
      }
    },
    localizedRoute(path) {
      return `/${this.currentLang}${path}`;
    }
  },
})

</script>
