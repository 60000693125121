// Ваш файл с пользовательскими правилами валидации (например, validation-rules.js)

import { extend } from '@/configs/vee-validate';
import i18n from '@/configs/i18n'; // Ваш файл конфигурации i18n
import client from '@/store/modules/client'; // Ваш модуль Vuex клиента или API сервис
import { required } from '@/configs/vee-validate/dist/rules';


extend('required', {
    ...required,
    message: (field, values) => {
        return i18n.t('required', { field:  i18n.t(values._field_)})
    }
})

// Правило для проверки длины IIN
extend('iin_length', {
    validate(value) {
        return value.length === 12;
    },
    message: () => i18n.t('iin_must_12')
});

extend('bin_validation', {
    validate(value) {
        const numericRegex = /^\d+$/;
        return numericRegex.test(value) && value.length === 13;
    },
    message: () => i18n.t('invalid_bin')
});

// Правило для проверки наличия только цифр в IIN
extend('iin_numeric', {
    validate(value) {
        const numericRegex = /^\d+$/;
        return numericRegex.test(value);
    },
    message: () => i18n.t('must_contain_only_digits')
});

// Правило для асинхронной проверки существования IIN
let timeoutIin = null;
extend('iin_exists', {
    validate(value) {
        return new Promise((resolve) => {
            if (!value) resolve(true); // Пропустить проверку, если значение пустое
            if (timeoutIin) {
                clearTimeout(timeoutIin);
            }
            timeoutIin = setTimeout(async () => {
                try {
                    await client.actions.CHECK_IIN_EXISTS({}, { iin: value });
                    resolve(true);
                } catch (error) {
                    resolve({ valid: false, data: error});
                }
            }, 500);
        });
    },
    message: (fieldName, params) => {
        return {
            error: true,
            message: 'IIN_has_already_been',
            data: params._result_.data,
        }
    }
});

// Правило для асинхронной проверки существования IIN
let timeoutUsername = null;
extend('username_exists', {
    validate(value) {
        return new Promise((resolve) => {
            if (!value) resolve(true); // Пропустить проверку, если значение пустое
            if (timeoutUsername) {
                clearTimeout(timeoutUsername);
            }
            timeoutUsername = setTimeout(async () => {
                try {
                    await client.actions.CHECK_USERNAME_EXISTS({}, { username: value });
                    resolve(true);
                } catch (error) {
                    resolve({ valid: false, data: error});
                }
            }, 500);
        });
    },
    message: (fieldName, params) => {
        return {
            error: true,
            message: 'username_has_already_been',
            data: params._result_.data,
        }
    }
});

// Правило для асинхронной проверки существования номера
let timeoutId = null;
extend('number_exists', {
    validate(value, { customMessage }) {
        return new Promise((resolve) => {
            if (value.length < 11) resolve(true); // Пропустить проверку, если длина значения меньше 11
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(async () => {
                try {
                    await client.actions.CHECK_NUMBER({}, { number: value });
                    resolve(true);
                } catch (error) {
                    resolve({ valid: false, data: error});
                }
            }, 500);
        });
    },
    params: ['customMessage'],
    message: (fieldName, params) => {
        return {
            error: true,
            message: 'number_already_exists',
            data: params._result_.data,
        }
    }
});

// Правило для кастомной проверки email
extend('custom_email', {
    validate(value) {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(value);
    },
    message: () => i18n.t('incorrect_email')
});
