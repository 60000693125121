import axios from '@/configs/axios';

const state = () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    statistics_main_page: {},
    region_all: [],
    source_all: [],
    organization_all: [],
    notification_list: [],
});

const getters = {
    isLoggedIn: state => !!state.user,
    accessToken: state => state.user?.access || '',
    GET_STATISTICS_MAIN_PAGE: s => s.statistics_main_page,
    GET_REGION_ALL: s => s.region_all,
    GET_SOURCE_ALL: s => s.source_all,
    GET_ORGANIZATION_ALL: s => s.organization_all,
    GET_NOTIFICATION_LIST: s => s.notification_list,
};

const mutations = {
    SET_STATISTICS_MAIN_PAGE(state, value){
        state.statistics_main_page = value
    },
    SET_REGION_ALL(state, value){
        state.region_all = value
    },
    SET_SOURCE_ALL(state, value){
        state.source_all = value
    },
    SET_ORGANIZATION_ALL(state, value){
        state.organization_all = value
    },
    SET_NOTIFICATION_LIST(state, value){
        state.notification_list = value
    },
};

const actions = {
    async CHECK_IIN_EXISTS({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('organizations/check_iin_exists/', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async GENERATE_USERNAME({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('organizations/generate_username/', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async CHECK_USERNAME_EXISTS({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('organizations/check_username_exists/', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async CHECK_NUMBER({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('organizations/check_number/', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async CHECK_NUMBER_V2({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.post('organizations/check_number_v2/', payload)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    resolve(error)
                });
        });
    },
    async STATISTICS_MAIN_PAGE({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/statistics_main_page/', payload)
                .then(response => {
                    commit('SET_STATISTICS_MAIN_PAGE', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async STATISTICS_REPORTS_PAGE({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/statistics_reports_page/', {params: payload})
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async REGION_ALL({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/region_all/',  {params: payload})
                .then(response => {
                    commit('SET_REGION_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async SOURCE_ALL({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/source_all/',  {params: payload})
                .then(response => {
                    commit('SET_SOURCE_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async ORGANIZATION_ALL({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('organizations/organization_all/',  {params: payload})
                .then(response => {
                    commit('SET_ORGANIZATION_ALL', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    async NOTIFICATION_LIST({commit}, payload){
        return new Promise((resolve, reject) => {
            axios.get('notification/notification_list/',  {params: payload})
                .then(response => {
                    commit('SET_NOTIFICATION_LIST', response.data);
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
