import { render, staticRenderFns } from "./ClientEdit.vue?vue&type=template&id=7bbc3614&scoped=true"
import script from "./ClientEdit.vue?vue&type=script&lang=js"
export * from "./ClientEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bbc3614",
  null
  
)

export default component.exports